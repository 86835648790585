import React, { useState, useEffect } from "react";
import Spinner from "react-bootstrap/Spinner";
import { storage } from "../App.js";
import { Card, Jumbotron, ScreenContainer } from "../components";

const HomeScreen = () => {
  const [devicesImage, setImage] = useState(false);
  const [streamingImage, setStreamingImage] = useState(false);
  const imagesRef = storage.ref();

  const fetchImages = async () => {
    const promises = await Promise.all([imagesRef.child("images/laptop.jpg").getDownloadURL(), imagesRef.child("images/recording_and_streaming.jpg").getDownloadURL()]);
    if (!!promises) {
      let url = promises.find((ref) => ref.includes('laptop'));
      let streamingUrl = promises.find((ref) => ref.includes('recording'));
      setImage(url);
      setStreamingImage(streamingUrl);
    }
  };

  // fetch image
  useEffect(() => {
    if (!devicesImage || !streamingImage) fetchImages();
  }, []);

  return (
    <ScreenContainer>
      <Card style={{ borderWidth: "0px", alignItems: 'stretch' }}>
        <Card.Text className="homeScreenText text-center">
          Hey there 👋, I'm <b>Kenneth Beers</b>
        </Card.Text>
        {/* <Card.Text className="homeScreenText text-center" style={{ fontSize: '1.2rem' }}>
        BBQ Extraordinaire 🍗 | Husband and Father 👨‍👩‍👧
        </Card.Text> */}
        <Card.Text className="homeScreenText text-center">
          I'm a <b>Full-Stack</b> Software Engineer with a passion in <b>Front-End</b> Development
        </Card.Text>
        <Card.Text className="homeScreenText text-center">
          This is my portfolio that I built with <b>React</b> and <b>Firebase</b>
        </Card.Text>
        <Card.Img variant="bottom" src={devicesImage ? devicesImage :undefined} />
        {!!devicesImage && (<Card.Text className="homeScreenText text-center">
          ☝️ This image was fetched from <b>Firebase Storage</b> 😉
        </Card.Text>)}
      </Card>
      <p className="brew-link text-center my-4">Check out my projects in the <a href="/brewhub">Brew Hub</a></p>
    </ScreenContainer>
  );
};
export default HomeScreen;
