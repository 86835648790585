import React from "react";
import { Form, Jumbotron, ScreenContainer } from "../components";

const ContactScreen = props => {
  return (
    <ScreenContainer>
      <Jumbotron style={{padding: '4rem 2rem'}}>
        <div style={{ textAlign: 'center' }}>
          <h3>Greatness Awaits!</h3>
          <p>Drop me a card and we will be in touch!</p>
        </div>
        <Form />
      </Jumbotron>
    </ScreenContainer>
  );
};
export default ContactScreen;
