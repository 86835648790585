import React, { useEffect, useState } from "react";
import Fade from 'react-bootstrap/Fade'
import Spinner from "react-bootstrap/Spinner";

import { Card, ScreenContainer } from "../components";
import { storage } from "../App.js";

const darkTextCardTitles = ['TechByBrewski\'s Client Portal', 'MinNote'];

const ProjectCard = ({ title, description, image, loading }) => {
  const [open, setOpen] = useState(false);

  const renderProjectDescription = () => {
    let html = [];
    for (const line of description) {
      html.push(<Card.Text className="projectDescription" as="li" style={{ display: !open ? 'none' : 'block' }} key={`${description[line]}-${line}`}>{line}</Card.Text>);
    }
    return html;
  }

  return (
    <Card className={`projectImageCard bg-dark text-${darkTextCardTitles.includes(title) ? 'black' : 'white'} text-center`} style={{border: 0}} onClick={() => setOpen(!open)}>
      {loading ? <Spinner animation="border" style={{ width: '8.5rem', height: '8.5rem', alignSelf: 'center', color: '#fff' }} /> : (
        <>
          <Card.Img variant="top" src={image} style={{
            filter: `blur(${open ? '1.5rem' : 0})`,
            WebkitFilter: `blur(${open ? '1.5rem' : 0})`
          }} />
          <Card.ImgOverlay>
            <Card.Text className="projectTitle" style={{ display: !open ? 'none' : 'block', marginTop: '20%', marginBottom: 16 }}>{title}</Card.Text>
            {renderProjectDescription()}
          </Card.ImgOverlay>
        </>
      )}
    </Card>
  );
}


const VSpacer = ({ height }) => {
  return <div style={{ height }} />;
}

const BrewHubScreen = () => {
  const imagesRef = storage.ref('projects');
  const cameraImageRef = storage.ref('images/camera.jpg');
  const [projectImages, setProjectImages] = useState([]);
  const [cameraImage, setCameraImage] = useState(false);

  useEffect(() => {
    if (projectImages.length === 0 && !!imagesRef) {
      fetchImages();
    }
    if (!cameraImage && !!cameraImageRef) {
      fetchCameraImage();
    }
  }, []);

  const fetchCameraImage = async () => {
    let url = await cameraImageRef.getDownloadURL();
    setCameraImage(url);
  }

  const fetchImages = async () => {
    const promises = [];
    if (imagesRef) {
      let images = await imagesRef.listAll();
      for (var image of images.items) {
        promises.push(image.getDownloadURL());
      }
      const imageArr = await Promise.all(promises);
      setProjectImages(imageArr);
    }
  };

  return (
    <ScreenContainer>
      <div style={{ textAlign: 'center', color: '#fff', margin: '4rem 0rem' }}>
        <h5>Welcome to the Brew Hub</h5>
        <p>Below you will find a few projects that I've created. Brewed projects are currently live in production and projects still brewing are either a wip, or just testing out new features</p>
        <p style={{color: '#fff'}}><small>Each <b>IMAGE</b> below is being fetched from <b>Firebase Storage</b><br />Click each <b>IMAGE</b> to learn more</small></p>
      </div>

      <p className="brew-link">Brewed Projects</p>
      <ProjectCard
        loading={projectImages.length === 0}
        title="POF.org: CE Web Tracker Portal"
        image={
          projectImages.find(img => img.indexOf('POF') !== -1)
        }
        description={[
          "CI/CD pipeline via GitHub Actions with automatic deployment to Firebase Hosting",
          "Staging and Production applications",
          "Firebase emulators for development",
          "Features highly scaleable cloud firestore, cloud functions, and role based permissions",
          "Light and Dark mode theme switcher"
        ]} />
      <VSpacer height="2rem" />
      {projectImages.length > 0 && (<p style={{color: '#fff', textAlign: 'center', fontSize: '1.2rem'}}><small>Not software related 😂 but pretty cool. I like tech things, so why not</small></p>)}
      <ProjectCard
        loading={projectImages.length === 0}
        title="Video Streaming and Recordings"
        image={cameraImage}
        description={[
          `Live video streaming and recording services`,
          "This is a local service available to Shreveport, LA and it's surrounding areas"
        ]} />
      <VSpacer height="2rem" />
      <p className="brew-link">Projects currently brewing</p>
      <ProjectCard
        loading={projectImages.length === 0}
        title="Game Night Caddy"
        image={
          projectImages.find(img => img.indexOf('GameNight') !== -1)
        }
        description={[
          'Goal: Build a web application that allows users to create and manage their own game night events',
          'User can create a game night event and invite others to join',
          'Users could upload images of their game boards and add comments, descriptions, and other information',
          'Local scoreboard to track your progress, or results from previous game nights',
          'Tested and implemented: Facebook and Google authentication',
        ]} />
      <VSpacer height="2rem" />
      <ProjectCard
        loading={projectImages.length === 0}
        title="TechByBrewski's Client Portal"
        image={
          projectImages.find(img => img.indexOf('ClientPortal') !== -1)
        }
        description={[
          'Goal is to provide a single portal of contact for developer and clients',
          'Client can have many projects',
          'Each project maintains a chat log and upload section',
        ]} />
      <VSpacer height="2rem" />
      <h5 className="text-center my-4"><a href="/contact">Join the BREW HUB</a></h5>
    </ScreenContainer>
  );
};
export default BrewHubScreen;
