import React, { useState } from "react";
import Alert from 'react-bootstrap/Alert';
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { clientPortalStore } from '../App.js';
import { useEffect } from "react";

const initialState = {
  title: '',
  description: '',
  firstName: '',
  lastName: '',
  phoneNumber: '',
  email: ''
};

const MyForm = props => {
  const [show, setShow] = useState(false);
  const [valid, setValid] = useState(false);
  const [touched, setTouched] = useState([]);
  const [formValues, setFormValues] = useState(initialState);

  useEffect(() => {
    if(!formValues.title || !formValues.firstName || !formValues.lastName || !formValues.email) {
      setValid(false);
    } else {
      setValid(true);
    }
  }, [formValues]);

  const onSubmit = async (event) => {
    event.preventDefault();
    try {
    await clientPortalStore.collection('project_leads').add({
      contactEmail: formValues.email,
      contactName:  formValues.firstName + ' ' + formValues.lastName,
      contactPhone: formValues.phoneNumber,
      projectDescription: formValues.description,
      projectTitle: formValues.title
    });
    setShow(!show);
    setFormValues(initialState);
    } catch (error) {
      console.log(error);
      window.alert(error.message);
    }
  };

  const updateField = (field, val) => {
    setFormValues({...formValues, [field]: val})
  };

  const handleTouched = (field) => !touched.some((rec) => rec === field) ? setTouched([...touched, field]) : undefined;

  const isInvalid = (field) => touched.some(rec => rec === field) && !formValues[field];

  return (
    <Form onSubmit={onSubmit}>
      <Form.Group controlId="formBasicProjectTitle">
        <Form.Label>ProjectTitle</Form.Label>
        <Form.Control onClick={() => handleTouched('title')} onChange={(e) => updateField('title', e.target.value)} value={formValues.title} isInvalid={isInvalid('title')}/>
      </Form.Group>
      <Form.Group controlId="formBasicProjectDescription">
        <Form.Label>Project Description</Form.Label>
        <Form.Control as="textarea" rows="3" onChange={(e) => updateField('description', e.target.value)} value={formValues.description}/>
      </Form.Group>
      <Form.Group controlId="formBasicFirstName">
        <Form.Label>First Name</Form.Label>
        <Form.Control onClick={() => handleTouched('firstName')} onChange={(e) => updateField('firstName', e.target.value)} value={formValues.firstName} isInvalid={isInvalid('firstName')}/>
      </Form.Group>
      <Form.Group controlId="formBasicLastName">
        <Form.Label>Last Name</Form.Label>
        <Form.Control onClick={() => handleTouched('lastName')} onChange={(e) => updateField('lastName', e.target.value)} value={formValues.lastName} isInvalid={isInvalid('lastName')}/>
      </Form.Group>
      <Form.Group controlId="formBasicPhoneNumber">
        <Form.Label>Phone Number</Form.Label>
        <Form.Control onClick={() => handleTouched('phoneNumber')} onChange={(e) => updateField('phoneNumber', e.target.value)} value={formValues.phoneNumber} isInvalid={isInvalid('phoneNumber')}/>
      </Form.Group>
      <Form.Group controlId="formBasicEmail">
        <Form.Label>Email</Form.Label>
        <Form.Control onClick={() => handleTouched('email')} onChange={(e) => updateField('email', e.target.value)} value={formValues.email} isInvalid={isInvalid('email')}/>
      </Form.Group>
      <Button variant="primary" type="submit" disabled={!valid}>
        Submit
      </Button>
      {show && (
        <div style={{ textAlign: "center", marginTop: '15px' }} onClick={() => setShow(!show)}>
          <Alert variant="success">
            <p>Success 🥳!</p>
            <hr/>
            <p className="text-muted" style={{ marginTop: '20px' }}><small>Click to close</small></p>
          </Alert>
        </div>
      )}
    </Form>
  );
};
export default MyForm;
