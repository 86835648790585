import React from "react";
import { Container } from "./";

const ScreenContainer = props => (
  <Container fluid>
    {props.children}
  </Container>
);

export default ScreenContainer;
