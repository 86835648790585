import React, { useState, useEffect } from "react";
import { Jumbotron, ScreenContainer } from "../components";
import { storage } from "../App.js";

const AboutScreen = () => {
  const [laptopImage, setImage] = useState(false);
  const imagesRef = storage.ref();

  const fetchLaptopImage = async () => {
    let url = await imagesRef.child("images/laptop.jpg").getDownloadURL();
    if (url) setImage(url);
  };

  // fetch image
  useEffect(() => {
    if (!laptopImage) fetchLaptopImage();
  }, []);

  return (
    <ScreenContainer>
      <Jumbotron style={{ padding: "4rem 2rem" }}>
        <div style={{ textAlign: "center", marginBottom: "40px" }}>
          <blockquote className="blockquote">
            <q>
              Coming together is the beginning. Keeping together is progress.
              Working together is success.
            </q>
            <footer className="blockquote-footer">Henry Ford</footer>
          </blockquote>
        </div>
        <p className="text-center">I'm a full stack developer 🤙</p>
        <div style={{ height: 120 }} />
        <p className="text-center h4">Lets do something great together!</p>
        <div>
          <h5 style={{ textAlign: "center", margin: "40px 0px" }}>
            <a href="/contact">Join the Brew Hub</a>
          </h5>
          <hr/>
        </div>
      </Jumbotron>
    </ScreenContainer>
  );
};

export default AboutScreen;
