import React from 'react';
import {
  BrowserRouter,
  Switch,
  Route,
} from 'react-router-dom';

import { NavMenu } from '../components';
// screens
import AboutScreen from './AboutScreen';
import ContactScreen from './ContactScreen';
import HomeScreen from './HomeScreen';
import BrewHubScreen from './BrewHubScreen';

const Router = () => (
  <BrowserRouter>
    <NavMenu />
    <Switch>
      <Route path="/about">
        <AboutScreen />
      </Route>
      <Route path="/brewhub">
        <BrewHubScreen />
      </Route>
      <Route path="/contact">
        <ContactScreen />
      </Route>
      <Route path="/">
        <HomeScreen />
      </Route>
    </Switch>
  </BrowserRouter>
);

export default Router;