import React from 'react';
import ReactDOM from 'react-dom';
import {
  RecoilRoot
} from 'recoil';
import Spinner from "react-bootstrap/Spinner";

import * as serviceWorker from './serviceWorker';
import './custom.scss';
import App from './App';


ReactDOM.render( <
  React.StrictMode>
    <RecoilRoot>
      <React.Suspense fallback = {
        <Spinner animation = "border"
          variant = "dark" / >
      }>
        <App / >
      </React.Suspense>
    </RecoilRoot>
  </React.StrictMode>,
  document.getElementById('root')
);

serviceWorker.register();