import React from 'react'
import Router from './screens/Router'

var firebase = require('firebase/app')
require('firebase/analytics')
require('firebase/storage')
require('firebase/firestore')

const firebaseConfig = {
  apiKey: 'AIzaSyA2qZnYfloQ7fapX6dhMQ1bB2yfEkSW0_c',
  authDomain: 'portfolio-2f77d.firebaseapp.com',
  databaseURL: 'https://portfolio-2f77d.firebaseio.com',
  projectId: 'portfolio-2f77d',
  storageBucket: 'portfolio-2f77d.appspot.com',
  messagingSenderId: '824237699759',
  appId: '1:824237699759:web:a598fd8e35c5aac343da5a',
  measurementId: 'G-68NEGXM3S4'
}

const firebaseProjectsConfig = {
  apiKey: "AIzaSyC4_hAaR4YQ-YHp9SSdPAFOrDLhWKRrnMU",
  authDomain: "client-portal-304c3.firebaseapp.com",
  projectId: "client-portal-304c3",
  storageBucket: "client-portal-304c3.appspot.com",
  messagingSenderId: "874472608618",
  appId: "1:874472608618:web:229c89e450de25dbe28b13",
  measurementId: "G-FWHSJYCKSM"
}

function App() {
  return <Router / >
};

{/* Initialize Firebase */}
const fire = firebase.initializeApp(firebaseConfig)
const clientPortal = firebase.initializeApp(firebaseProjectsConfig, 'clientPortal');
export const clientPortalStore = clientPortal.firestore();
export const analytics = fire.analytics()
export const storage = fire.storage()
export default App;